<template>

    <div class="form-item10">
      <van-swipe-cell>
        <template #left>
          <van-button square type="primary" text="选择" />
        </template>
        <van-cell class="mb10" :border="false" title="订单信息"  />
        <template #right>
          <van-button square type="danger" text="删除" />
          <van-button square type="primary" text="收藏" />
        </template>
      </van-swipe-cell>
      <van-swipe-cell :before-close="beforeClose">
        <van-card  price="2.00" desc="描述信息" title="商品标题" class="goods-card"
                  thumb="https://img.yzcdn.cn/vant/cat.jpeg" />
        <template #right>
          <van-button square text="删除" type="danger" class="delete-button" />
        </template>
      </van-swipe-cell>
      <!-- 结算按钮 -->
      <van-submit-bar :price="3050" button-text="立即支付" @submit="onSubmit" />
    </div>

</template>
<script>
import { addOrder, centreInfoWeb, orderbuyPageWeb, weChatPay } from '@/http'
import { Button, Dialog, SubmitBar, Toast } from 'vant'

export default {
  name: 'buy',
  data() {
    return {
      courseDetail: {},
      payType: '',
      ndate: '',
      isSpinShow: false,
      doller: false, // 是否使用学习币
      nowIndex: 0, // 支付方式
      isShow: false, // 支付确认
      agree: true, // 同意协议
      permitCoin: false, // 是否允许使用学习币  true允许使用学习币支付
      vipxy: false, // 是否同意vip使用协议
      type: -1,
      // 定时器相关
      newTimer: false,
      timer: '', // 定时器
      orderStatus: '', // 后台订单状态
      status: 0, // 支付状态:0默认值，1未完成，2完成，3关闭

      sure: false, // 支付确认框是否显示
      surePay: false, // 是否确认支付
      order: '', // 订单
      detail: { // 订单部分信息
        orderId: this.$route.query.orderId, // 订单号
        content: '', // 订单提示
        img: this.$route.query.img, // 订单图片
        needRecharge: 0, // 学习币支付是否够用 1不 足 2够用
        price: 0, // 商品价格
        shortAmount: 0, // 学习币支付缺少的学习币数量
        title: this.$route.query.title,
        userAmount: 0, // 用户的学习币余额
        // 支付相关
        url: '', // 微信二维码请求链接
        payType: 1 // 判断支付方式选择  1支付宝   2微信  （3学习币支付没有使用这个参数做判断）
      },
      count: 10,
      userId: '',
      courseId: this.$route.query.courseId
    }
  },
  methods: {
    onSubmit() {
      this.buy()
    },
    buy() {
      Toast.loading({
        message: '创建订单中...',
        forbidClick: true
      })
      var that = this
      addOrder({
        type: 'OT_COURSE',
        targetId: that.$route.query.courseId,
        applyType: '3'
      })
        .then((res) => {
          if (res.code === 200) {
            Toast.success('订单创建成功')
            var orderId = res.data.orderId
            var payType = that.data.payType
            if (payType === 'coin') {
              that.payBalance(orderId)
              return
            }
            that.payforTap(orderId)
          } else {
            Toast(res.info)
          }
        })
      },
    // 立即支付
    payforTap(orderId) {
      var that = this
      var openid = 'ojAha5Tr0akFzH92rQo51iYm3mQ4'
      if (!openid) {
        Dialog.confirm({
          title: '提醒',
          message: '您还没有登录,请登录后再操作'
        })
      } else {
        that.pay(openid.openid, orderId)
      }
    },

    pay(opndId, orderId) {
      if (!orderId) {
       Toast.fail({
          title: '获取订单信息失败',
          icon: 'none'
        })
        return
      }
    }

  },
created() {
},
  mounted() {
    console.log(this.$route.query.courseId)
  }
}

</script>

<style scoped>

.goods-card {
  margin: 0;
  background-color: #fff;
}
</style>
